import React from "react"
import { StoreProvider } from "./src/context/StoreContext"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)

export const onRouteUpdate = ({ location }, pluginOptions) => {
  if (process.env.NODE_ENV === "production") {
    if (window.snaptr) {
      window.snaptr("track", "PAGE_VIEW")
      window.snaptr("track", "VIEW_CONTENT")
    }

    if (window.gtag) {
      window.gtag("js", new Date())
      window.gtag("config", "AW-699771945")
    }
  }
}
