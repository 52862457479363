// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0ffb3358-9779-4571-8de6-f3e0007250fd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_s7d7jY7GZ",
    "aws_user_pools_web_client_id": "6r9ridmbmf48b3s6jke3ta5dlu",
    "oauth": {},
    "aws_user_files_s3_bucket": "waggtopiaimages-env",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
