// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-template-js": () => import("../src/templates/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-designer-template-js": () => import("../src/templates/DesignerTemplate.js" /* webpackChunkName: "component---src-templates-designer-template-js" */),
  "component---src-templates-legal-template-js": () => import("../src/templates/LegalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("../src/pages/thank_you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-track-js": () => import("../src/pages/track.js" /* webpackChunkName: "component---src-pages-track-js" */),
  "component---src-pages-upgrade-js": () => import("../src/pages/upgrade.js" /* webpackChunkName: "component---src-pages-upgrade-js" */)
}

