import React, { createContext, useState, useEffect } from "react"
import Client from "shopify-buy"
import Amplify, { Storage } from "aws-amplify"
import fetch from 'isomorphic-fetch';
import awsconfig from "../aws-exports"

Amplify.configure(awsconfig)

const client = Client.buildClient({
  domain: "waggtopia.myshopify.com",
  storefrontAccessToken: "a3f7141d76f1a83eb0fed4dadefd9cb8",
}, fetch)

const defaultValues = {
  isCartOpen: false,
  toggleCartOpen: () => { },
  cart: [],
  addProductToCart: () => { },
  updateLineItem: () => { },
  client,
  checkout: { lineItems: [] },
  removeProductFromCart: () => { },
  checkCoupon: () => { },
  removeCoupon: () => { },
  isLoading: false,
}

export const StoreContext = createContext(defaultValues)

// Check if it's a browser
const isBrowser = typeof window !== "undefined"

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [isCartOpen, setCartOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const toggleCartOpen = () => setCartOpen(!isCartOpen)

  useEffect(() => {
    initializeCheckout()
  }, []) // Empty array means this will only run once at mount

  const getNewCheckout = async () => {
    try {
      const newCheckout = await client.checkout.create()
      if (isBrowser) localStorage.setItem("checkout_id", newCheckout.id)

      return newCheckout
    } catch (e) {
      console.error(e)
    }
  }
  const initializeCheckout = async () => {
    try {
      // Check if id exists
      const currentCheckoutId = isBrowser
        ? localStorage.getItem("checkout_id")
        : null

      let newCheckout = null
      if (currentCheckoutId) {
        // If id exists, fetch checkout from Shopify
        newCheckout = await client.checkout.fetch(currentCheckoutId)
        if (newCheckout) {
          if (newCheckout.completedAt) {
            newCheckout = await getNewCheckout()
          }
        } else {
          // Couldn't retrieve cart with id. Get a new one.
          newCheckout = await getNewCheckout()
        }
      } else {
        // If id does not, create new checkout
        newCheckout = await getNewCheckout()
      }

      // Set checkout to state
      setCheckout(newCheckout)
    } catch (e) {
      console.error(e)
    }
  }

  const addProductToCart = async ({ variantId, price, customAttributes, ref }) => {
    try {
      setLoading(true)

      if (process.env.NODE_ENV === "production") {
        if (window.fbq) {
          window.fbq("track", "AddToCart", {
            value: price,
            currency: 'USD'
          })
        }

        if (window.snaptr) {
          window.snaptr("track", "ADD_CART")
        }
      }

      const lineItems = [
        {
          variantId,
          quantity: 1,
          customAttributes,
        },
      ]

      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      )

      if (process.env.NODE_ENV === "production") {
        const addedItem = newCheckout.lineItems.filter(
          item => item.variant.id === variantId
        )[0]

        if (customAttributes) {
          if (customAttributes.length > 0) {
            if (window._learnq) {
              window._learnq.push([
                "identify",
                {
                  $email: customAttributes.find(atr => atr.key === "_Email").value,
                },
              ])
            }
          }
        }

        if (window._learnq) {
          const klaviyoCart = {
            $value: parseFloat(newCheckout.totalPrice),
            AddedItemProductName: addedItem.title,
            AddedItemProductID: addedItem.variant.product.id,
            AddedItem_SKU: addedItem.variant.sku,
            AddedItem_ImageURL: addedItem.variant.image.src,
            AddedItem_URL: `https://waggtopia.com${ref}`,
            AddedItem_Price: parseFloat(addedItem.variant.price),
            AddedItem_Quantity: 1,
            ItemNames: newCheckout.lineItems.map(item => item.title),
            CheckoutURL: newCheckout.webUrl,
            Items: newCheckout.lineItems.map(item => {
              let productUrl = ""
              if (item.customAttributes.length > 0) {
                const handle = item.customAttributes.find(
                  atr => atr.key === "_handle"
                ).value

                productUrl = `https://waggtopia.com/product/${handle}`
              }
              return {
                ProductID: item.variant.product.id,
                SKU: item.variant.sku,
                ProductName: item.title,
                Quantity: item.quantity,
                ItemPrice: parseFloat(item.variant.price),
                RowTotal: parseFloat(item.variant.price) * item.quantity,
                ProductURL: productUrl,
                ImageURL: item.variant.image.src,
              }
            }),
          }

          window._learnq.push(["track", "Added to Cart", klaviyoCart])
        }
      }

      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const updateLineItem = async (lineItemId, variantId, customAttributes) => {
    try {
      setLoading(true)
      const lineItems = [
        {
          id: lineItemId,
          quantity: 1,
          customAttributes,
          variantId,
        },
      ]

      const newCheckout = await client.checkout.updateLineItems(
        checkout.id,
        lineItems
      )

      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const removeProductFromCart = async lineItemId => {
    try {
      setLoading(true)
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ])

      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const checkCoupon = async coupon => {
    try {
      setLoading(true)
      const newCheckout = await client.checkout.addDiscount(checkout.id, coupon)

      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  const removeCoupon = async coupon => {
    try {
      setLoading(true)
      const newCheckout = await client.checkout.removeDiscount(
        checkout.id,
        coupon
      )

      setCheckout(newCheckout)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }
  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addProductToCart,
        checkout,
        toggleCartOpen,
        isCartOpen,
        removeProductFromCart,
        checkCoupon,
        removeCoupon,
        isLoading,
        updateLineItem,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
